import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'entries', 'pagination' ]

  connect() {
    //console.log("hello infinite_scroll")
    this.loadingMore = false
  }

  scroll() {
    if (this.loadingMore) { return }

    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }

    let url = next_page.href
    
    const body = document.body
    const html = document.documentElement
    const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    if (window.pageYOffset >= height - window.innerHeight) {
      //console.log('END OF PAGE', window.pageYOffset, height, window.innerHeight)
      this.loadMore(url)
    }
  }

  loadMore(url) {
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      beforeSend: () => {
        this.loadingMore = true
        return true
      },
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        this.currentPage = data.current_page
        this.loadingMore = false
        //console.log(this.currentPage)
      }
    })
  }
  
}