import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  //static targets = [ "form" ]

  update() {
    this.element.submit()
  }

}