import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

const highlight = ($elem) => {
  $elem.classList.remove("bg-transition")
  $elem.classList.add("bg-yellow");
  
  window.setTimeout(() => {
    $elem.classList.add("bg-transition")
    $elem.classList.remove("bg-yellow");
  }, 0);
};

export default class extends Controller {
  static targets = [ "show", "formWrapper", "form" ]
  static values = {
    attr: String
  }

  edit() {
    this.showTarget.style.display = 'none'
    this.formWrapperTarget.style.display = 'block'
  }

  close() {
    this.showTarget.style.display = 'block'
    this.formWrapperTarget.style.display = 'none'
  }

  update() {
    Rails.fire(this.formTarget, 'submit')
  }

  onUpdateSuccess(event) {
    const [data] = event.detail;
    this.showTarget.innerHTML = data[this.attrValue].body
    this.close()
    highlight(this.showTarget)
  }
}