import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"];

  inject(event) {
    const fetchOptions = {
      method: 'GET',
      mode: 'cors',
      cache: 'default'
    }
    
    const modalInsert = document.querySelector('#modalInsert')

    fetch(this.linkTarget.href, fetchOptions)
      .then(response => 
        response.json()
      )
      .then(data => {
        modalInsert.innerHTML = ''
        data.forEach(project => {
          const html = `<td><a href="/projects/${project.id}">${project.full_address}(L: ${project.project_lots_count}) (RL: ${project.relocation_items_count})</a></td>`
          modalInsert.insertAdjacentHTML('beforeend', html)
        })
      })
  }
}