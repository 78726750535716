import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

const highlight = ($elem) => {
  $elem.classList.remove("bg-transition")
  $elem.classList.add("bg-yellow");
  
  window.setTimeout(() => {
    $elem.classList.add("bg-transition")
    $elem.classList.remove("bg-yellow");
  }, 0);
};

export default class extends Controller {
  static targets = [ "labelWrapper", "valueWrapper", "selectWrapper", "form", "usersData" ]

  connect() {
    this.$old_resp_el = this.labelWrapperTarget.getElementsByTagName('span')[0]
  }

  open() {
    this.valueWrapperTarget.style.display = 'none'
    this.selectWrapperTarget.style.display = 'block'
  }

  update(e) {
    this.selectedValue = e.target.value
    Rails.fire(this.formTarget, 'submit')
  }

  close() {
    this.valueWrapperTarget.style.display = 'block'
    this.selectWrapperTarget.style.display = 'none'
  }

  onUpdateSuccess() {
    const users = JSON.parse(this.usersDataTarget.innerHTML)
    this.valueWrapperTarget.style.display = 'block'
    this.selectWrapperTarget.style.display = 'none'
    const user = users.find(u => u.id == this.selectedValue)
    if (user) {
      this.valueWrapperTarget.innerHTML = user.name
      highlight(this.valueWrapperTarget)
      this.$old_resp_el.style.display = 'none'
    } else {
      this.valueWrapperTarget.innerHTML = "Sélectionnez..."
      this.$old_resp_el.style.display = 'inline'
    }
  }
}