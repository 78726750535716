// Stimulus controller
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    console.log('connect table focus id #', this.element.id)
    this.focusId = new URLSearchParams(window.location.search).get('focus_id');
    if (this.focusId) {
      this.scrollToItem();
    }
  }

  scrollToItem() {
    let itemElement = document.getElementById(this.focusId);
    if (itemElement) {
      // Item found, scroll into view and highlight
      itemElement.scrollIntoView();
      itemElement.style.backgroundColor = 'yellow';
      itemElement.click()
    } else {
      console.log('Number of rows:', this.element.querySelectorAll('tr').length);

      this.element.scrollTop = this.element.scrollHeight;
      
      // Use a delay before trying again
      setTimeout(() => {
        this.scrollToItem();
      }, 1); // Adjust the timeout as needed
    }
  }
}
