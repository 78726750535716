import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field", "show", "edit" ]

  open() {
    this.showTarget.style.display = 'none'
    this.editTarget.style.display = 'block'
    this.editTarget.getElementsByTagName('input')[0].focus()
  }

  close() {
    this.showTarget.style.display = 'block'
    this.editTarget.style.display = 'none'
  }

  update(e) {
    this.form = this.fieldTarget.closest('form')
    Rails.fire(this.form, 'submit')
  }

}