import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkboxes"]

  connect() {
    this.expanded = false
  }

  showCheckboxes(e) {
    e.stopPropagation()
    if (!this.expanded) {
      this._open()
    } else {
      this._close()
    }
  }

  out(e) {
    this._close()
    e.stopPropagation()
  }

  in(e) {
    e.stopPropagation()
  }

  _open() {
    this.checkboxesTarget.style.display = "block";
    this.expanded = true;
  }

  _close() {
    this.checkboxesTarget.style.display = "none";
    this.expanded = false;
  }

}