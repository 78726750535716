import { Controller } from "@hotwired/stimulus"
import homeRenovationIcon from 'images/home-renovation-icon.svg';
import homeDemolitionIcon from 'images/home-demolition-icon.svg';

export default class extends Controller {
  static targets = ["input", "output", "outputValue", "loader", "removeButton"]

  search() {
    console.log("search")
    this.loaderTarget.classList.remove('hidden'); // Show loader

    fetch(`/projects/search?query=${this.inputTarget.value}`, {
      headers: {
        Accept: "application/json"
      }
    })
    .then(response => response.json())
    .then(data => {
      this.outputTarget.innerHTML = data.map(project => {
        const iconUrl = project.kind === 'restoration' ? homeRenovationIcon : homeDemolitionIcon;

        return `
          <li class="text-gray-900 hover:bg-gray-300 relative cursor-pointer select-none py-2 pl-3 pr-9 flex items-center"
            role="option"
            data-action="click->autocomplete#select"
            data-value="${project.id}"
          >
            <img src="${iconUrl}" alt="icon" class="w-5 h-5 mr-2">
            <span class="font-normal block truncate">${project.full_address}</span>
          </li>
        `
      }).join('');
    })
    .finally(() => {
      this.loaderTarget.classList.add('hidden'); // Hide loader
    });
  }

  focus() {
    console.log("focus")
    this.outputTarget.classList.remove('hidden');
  }

  blur() {
    console.log("blur")
    this.outputTarget.classList.add('hidden');
  }

  select(event) {
    console.log("select")
    const projectId = event.currentTarget.dataset.value;
    this.outputValueTarget.value = projectId;
    this.inputTarget.value = event.currentTarget.textContent?.trim();
    this.outputTarget.innerHTML = "";
    this.removeButtonTarget.classList.remove('hidden'); // Show Remove button
  }

  clear(event) {
    console.log("clear")
    event.preventDefault();
    this.inputTarget.value = "";
    this.outputValueTarget.value = "";
    this.removeButtonTarget.classList.add('hidden'); // Hide Remove button
  }

  checkForEmptyField(event) {
    console.log("checkForEmptyField")
    if (this.inputTarget.value === '') {
      this.clear(event);
    }
  }

  close(event) {
    console.log("close")
    this.clear(event)
  }
}
