import { Controller } from "@hotwired/stimulus"

function htmlToElement(html) {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

export default class extends Controller {
  static targets = [ "links", "template", "insertBlock" ]

  add_association(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    if (this.hasInsertBlockTarget) {
      this.insertBlockTarget.append(htmlToElement(content))
    } else {
      this.linksTarget.insertAdjacentHTML('beforebegin', content)
    }
  }

  remove_association(event) {
    event.preventDefault()
    let wrapper = event.target.closest('.nested-fields')
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }
  
}