import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import adaptivePlugin from '@fullcalendar/adaptive';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import RenovationIcon from '../images/home-renovation-icon.svg'
import DemolitionIcon from '../images/home-demolition-icon.svg'


export default class extends Controller {
  static targets = [ "root", "form" ]

  connect() {
    this.calendar = buildCalendar(this.rootTarget)
    loadDataAndRender(this.formTarget, this.calendar)
  }

  prev(e) {
    e.preventDefault()
    this.calendar.prev()
  }

  next(e) {
    e.preventDefault()
    this.calendar.next()
  }

  today(e) {
    e.preventDefault()
    this.calendar.today()
  }
}


const buildCalendar = (root) => {
  return new Calendar(root, {
    plugins: [ adaptivePlugin, interactionPlugin, resourceTimelinePlugin ],
    schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",  
    locale: 'fr',
    timeZone: 'UTC',
    editable: true, // enable draggable Phases,
    selectable: false,
    aspectRatio: 1.85,
    scrollTime: '00:00', // undo default 6am scrollTime
    headerToolbar: '',
    initialView: 'resourceTimelineYear',
    resourceOrder: 'title',
    views: {
      resourceTimelineYear: {
        type: 'resourceTimeline',
        duration: { years: 4 },
        nowIndicator: true
      }
    },
    viewDidMount: (arg) => {
      showYearSeparator(arg.el)
    },
    expandRows: false,
    stickyFooterScrollbar: true,
    windowResize: function(arg) {
      console.log('The calendar has adjusted to a window resize. Current view: ' + arg.view.type);
    },
    // Resources
    resourceAreaWidth: '20%',
    resourceAreaHeaderContent: 'Adresses',
    resources: [],
    resourceLabelDidMount: renderResourceLabel,
    // Events
    events: [],
    eventDrop: updateEvent,
    eventResize: updateEvent
  });
}


const loadDataAndRender = (form, calendar) => {
  const fetchOptions = {
    method: 'GET',
    mode: 'cors',
    cache: 'default'
  }
  fetch('/projects/planning_data?' + new URLSearchParams(serializeObject(form)), fetchOptions)
    .then(response => 
      response.json()
    )
    .then(data => {
      data.forEach(project => {
        calendar.addResource({
          id: project.id,
          title: project.full_address,
          housing_blocked: project.housing_blocked,
          kind: project.kind
        })
        project.planning_phases.forEach(phase => {
          calendar.addEvent({
            id: phase.id,
            resourceId: phase.project_id,
            title: phase.planning_text,
            start: phase.start_on,
            end: phase.end_on,
            backgroundColor: PHASE_COLORS(phase.kind),
            constraint: {
              resourceIds: [ phase.project_id ] // constrain dragging to these
            }
          })
        })
      })
    })
    .then(() => {
      calendar.render()
    })
}

const renderResourceLabel = (info) => {
  const linkEl = document.createElement('a')
  linkEl.setAttribute('href', `/projects/${info.resource.id}/d/housing`)
  const imgEl = document.createElement('img')
  imgEl.setAttribute('src', info.resource.extendedProps.kind === 'restoration' ? RenovationIcon : DemolitionIcon)
  Object.assign(imgEl.style, {
    height: '16px',
    'vertical-align': 'bottom'
  })
  Object.assign(linkEl.style, {
    height: '16px',
    display: 'inline-block',
    'margin-right': '6px',
    'vertical-align': 'sub'
  })
  linkEl.appendChild(imgEl)
  const mainEl = info.el.querySelector('.fc-datagrid-cell-main')
  mainEl.prepend(linkEl)
  mainEl.style.color = info.resource.extendedProps.housing_blocked ? 'red' : 'black'
}

const updateEvent = (e) => {
  const myInit = {
    method: 'PUT',
    mode: 'cors',
    cache: 'default',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ phase: {
      start_on: e.event.start,
      end_on: e.event.end
    }})
  }
  fetch('/phases/' + e.event.id, myInit)
    .then(response => 
      response.json()
    )
    .then(json => {
      // we want to rerender all resource events with server dates
      const calendar = e.view.calendar
      const resource = e.event.getResources()[0]
      const events = resource.getEvents()
      json.planning_phases.forEach(phase => {
        const event = events.find(event => event.id === phase.id.toString())
        if (event) {
          event.setDates(phase.start_on, phase.end_on)
        } else {
          calendar.addEvent({
            id: phase.id,
            resourceId: phase.project_id,
            title: phase.planning_text,
            start: phase.start_on,
            end: phase.end_on,
            backgroundColor: PHASE_COLORS(phase.kind),
            constraint: {
              resourceIds: [ phase.project_id ] // constrain dragging to these
            }
          })
        }
      })
    })
}

const PHASE_COLORS = (kind) => {
  return {
    'diagnosis':         'blue',
    'building_permit':   'green',
    'works_rfp':         'orange',
    'preliminary_works': 'red',
    'works_start':       'purple',
    'works_acceptance':  'brown',
    'works_inspection':  'silver'
  }[kind]
}

const serializeObject = (form) => {
  // Create a new FormData object
  const formData = new FormData(form);

  // Create an object to hold the name/value pairs
  const pairs = {};

  // Add each name/value pair to the object
  for (const [name, value] of formData) {
    pairs[name] = value;
  }

  // Return the object
  return pairs;
}

const showYearSeparator = (el) => {
  const yearCells = el.querySelectorAll("td[data-date$='01-01']")
  yearCells.forEach(cell => {
    cell.style.borderColor = 'darkGray'
    cell.style.borderWidth = '2px'
  })
}
