import { Controller } from "@hotwired/stimulus"
import homeRenovationIcon from 'images/home-renovation-icon.svg';
import homeDemolitionIcon from 'images/home-demolition-icon.svg';

export default class extends Controller {
  static targets = ["input", "output", "loader", "removeButton"]

  search() {
    this.outputTarget.classList.remove('hidden'); // Show output
    this.loaderTarget.classList.remove('hidden'); // Show loader

    fetch(`/projects/search?query=${this.inputTarget.value}`, {
      headers: {
        Accept: "application/json"
      }
    })
    .then(response => response.json())
    .then(data => {
      this.outputTarget.innerHTML = data.map(project => {
        const iconUrl = project.kind === 'restoration' ? homeRenovationIcon : homeDemolitionIcon;

        return `
          <li class="text-gray-900 hover:bg-gray-300 relative cursor-pointer select-none py-2 pl-3 pr-9 flex items-center"
            role="option"
            data-value="${project.id}"
            data-action="click->quicksearch#select"
          >
            <img src="${iconUrl}" alt="icon" class="w-5 h-5 mr-2">
            <a href="/projects/${project.id}" class="font-normal block truncate">
              ${project.full_address}
            </a>
          </li>
        `
      }).join('');
    })
    .finally(() => {
      this.loaderTarget.classList.add('hidden'); // Hide loader
    });
  }

  focus(event) {
  }

  blur(event) {
    console.log("blur")
    //this.clear(event)
  }

  select(event) {
    console.log("select")
    this.inputTarget.value = event.currentTarget.textContent?.trim();
    this.outputTarget.innerHTML = "";
    this.removeButtonTarget.classList.remove('hidden'); // Show Remove button
  }

  clear(event) {
    this.inputTarget.value = "";
    this.removeButtonTarget.classList.add('hidden'); // Hide Remove button
    this.outputTarget.classList.add('hidden'); // Hide output
  }
}
