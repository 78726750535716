import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["kindField", "housingItem"]

  connect() {
    this.toggleItems();
  }

  toggleItems() {
    const kind = this.kindFieldTarget.value;
    console.log(kind);

    if (kind === "housing") {
      this.housingItemTargets.forEach((element) => {
        element.classList.remove("hidden");
      });
    } else {
      this.housingItemTargets.forEach((element) => {
        element.classList.add("hidden");
      });
    }
  }
}
