import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["wrapper"]

  connect() {
    //console.log("hello scroll_position")
    this.wrapperTarget.scroll({
      top: localStorage.getItem('rememberScrollTop'),
      left: localStorage.getItem('rememberScrollLeft'),
      behavior: 'instant'
    })
  }

  scroll() {
    //console.log("scroll @ scroll_position")
    this.saveScrollPosition()
  }

  saveScrollPosition() {
    localStorage.setItem('rememberScrollTop', this.wrapperTarget.scrollTop)
    localStorage.setItem('rememberScrollLeft', this.wrapperTarget.scrollLeft)
  }
  
}