import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "parent", "child" ]

  select() {
    console.log(`select ${this.parentTarget.value}`)
    this.loadChildOptions()
  }

  loadChildOptions() {
    console.log(`loadChildOptions ${this.parentTarget.value}`)
    const childResources = this.childTarget.dataset.childResources // i.e. "neighbourhoods"
    const parentResourceId = this.parentTarget.dataset.parentResourceId // i.e. "town_id"
    this.childTarget.innerHTML = ''

    Rails.ajax({
      type: 'GET',
      url: `/${childResources}?${parentResourceId}=${this.parentTarget.value}`,
      dataType: 'json',
      success: (data) => {
        if (data && Array.isArray(data)) {
          this.childTarget.innerHTML = data.map(option => `<option value="${option.id}">${option.name}</option>`).join('')
        }
      },
      error: (data) => {
        console.log(data)
      }
    })
  }
}