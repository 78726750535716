import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
export default class extends Controller {

  open(e) {
    e.stopPropagation();
    e.preventDefault();

    // Make sure to remove any existing input first to avoid multiple inputs
    if (this.hasInput) {
      return;
    }

    this.cell = e.target
    this.url = this.cell.dataset.url

    // Save the current text
    const currentValue = this.cell.textContent.trim();

    // Create an input element
    const input = document.createElement('input');
    input.type = 'text';
    input.value = currentValue;
    input.classList.add('w-full', 'text-xs', 'p-1');

    // Replace the cell content with the input
    this.cell.innerHTML = '';
    this.cell.appendChild(input);
    input.focus();

    // Add event listener for when the user presses enter
    input.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        this.save(input.value);
      }
    });

    // Add event listener for when the input loses focus
    input.addEventListener('blur', () => {
      this.save(input.value);
    });

    this.hasInput = true;
  }

  save(newValue) {
    const params = { [this.cell.dataset.klass]: { [this.cell.dataset.attribute]: newValue } };
    // Get the CSRF token from the meta tag
    const csrfToken = document.querySelector("[name='csrf-token']").content;
  
    fetch(this.url, {
      method: 'PATCH',
      mode: 'cors', // This may not be needed if you're not making cross-origin requests
      cache: 'no-cache',
      credentials: 'include', // This is needed for cookies to be sent with the request (like the session cookie)
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken // Include the CSRF token in the request headers
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      // Update the cell with the new value or handle the response data
      this.cell.textContent = newValue;
      this.hasInput = false;
    })
    .catch(error => {
      console.error('Error updating the value:', error);
      // Handle any errors, perhaps revert the cell content or show an error message
    });
  }
  
}
