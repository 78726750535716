import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'entries', 'pagination', 'wrapper', 'table' ]

  connect() {
    console.log("hello intersection_load", this.wrapperTarget)
    this.loadingMore = false

    let options = {
      root: this.wrapperTarget,
      rootMargin: '0px',
      threshold: 1.0
    }
    
    this.observer = new IntersectionObserver(this.callback, options);
  }

  callback() {
    console.log("intersect callback!")
  }

  loadMore(url) {
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      beforeSend: () => {
        this.loadingMore = true
        return true
      },
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        this.currentPage = data.current_page
        this.loadingMore = false
        //console.log(this.currentPage)
      }
    })
  }
  
}